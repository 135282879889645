export function useCityCookie() {
  const data = useCookie<any>('city', {
    default: () => ({
      delivery_commission: 0,
      description: '',
      end_work: '19:00:00',
      id: 6,
      is_some_area: 0,
      name: 'Ақтөбе',
      order_price_min: 3000,
      slug: 'aktobe',
      start_work: '09:00:00',
      url: 'aktobe',
      whatsapp_phone: '77769384242',
    }),
    maxAge: 60 * 60 * 60 * 24 * 30,
  })
  function set(newVal: any) {
    data.value = newVal
  }
  function get() {
    return data.value
  }
  return {
    data,
    get,
    set,
  }
}
